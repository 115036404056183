import { Campaign } from 'views/types/tasks';
import { create, StateCreator, useStore } from 'zustand';
type TCampaignStoreState = {
  campaignList: Campaign[];
  clear: () => void;
};

export const createCampaignStoreFunction: StateCreator<
  TCampaignStoreState
> = set => ({
  campaignList: [],
  clear: () =>
    set({
      campaignList: [],
    }),
});

export const campaignStore = create<TCampaignStoreState>(
  createCampaignStoreFunction
);

export const useCampaignStore = () => useStore(campaignStore);
