// Custom components
import { InputHTMLAttributes, forwardRef } from 'react';

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  id: string;
  label?: string;
  extra: string;
  placeholder: string;
  variant: string;
  state?: string;
  disabled?: boolean;
  type?: string;
  hint?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref) => {
    const {
      label,
      id,
      extra,
      placeholder,
      variant,
      state,
      hint,
      disabled,
      ...inputProps
    } = props;

    return (
      <div className={`${extra}`}>
        {!!label && (
          <label
            htmlFor={id}
            className={`text-sm text-navy-700 dark:text-white text-[16px] ${
              variant === 'auth' ? 'ml-1.5 font-medium' : 'ml-3 font-bold'
            }`}
          >
            {label}
          </label>
        )}
        {hint && (
          <p className="text-[12px] text-gray-700 dark:text-gray-300">{hint}</p>
        )}
        <textarea
          disabled={disabled}
          id={id}
          placeholder={placeholder}
          rows={3}
          className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
            disabled === true
              ? '!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]'
              : state === 'error'
                ? 'border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400'
                : state === 'success'
                  ? 'border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400'
                  : 'border-gray-200 dark:!border-white/10 dark:text-white'
          }`}
          ref={ref}
          {...inputProps}
        />
      </div>
    );
  }
);

export default TextArea;
