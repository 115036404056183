import { CampaignTask, TaskType } from 'views/types/tasks';
import { MdClose, MdEdit } from 'react-icons/md';
import { tasksService } from 'views/services/tasksService';
import { toast } from 'App';

export const CampaignTaskItem = ({
  task,
  handleOpenTaskModal,
}: {
  task: CampaignTask;
  handleOpenTaskModal: (isOpen: boolean, task: CampaignTask) => void;
}) => {
  const handleDelete = async () => {
    const isCampaign = !!task.campaignId;
    if (task.type === TaskType.QUIZ) {
      toast({
        title: 'Error',
        description: 'You can only edit QUIZ but not delete',
        status: 'error',
        position: 'top',
      });
      return;
    }
    try {
      if (window.confirm(`Are you sure you want to delete?`)) {
        await tasksService.deleteTask(task.id, isCampaign);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const handleOpen = (isOpen: boolean) => {
    handleOpenTaskModal(isOpen, task);
  };
  return (
    <div className="flex flex-row justify-between border border-purple-600 gap-1 rounded-xl p-2 items-center text-sm">
      <p className="w-5/6">{task.title}</p>

      <div className="flex flx-row gap-1">
        <button
          onClick={() => handleOpen(true)}
          type="button"
          className="text-violet11 hover:bg-violet4 focus:shadow-violet7 right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
          aria-label="Edit"
        >
          <MdEdit />
        </button>
        <button
          type="button"
          className="text-violet11 hover:bg-violet4 focus:shadow-violet7 right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
          aria-label="Close"
          onClick={handleDelete}
        >
          <MdClose />
        </button>
      </div>
    </div>
  );
};
