import { $api } from './interceptor';

export const comboApi = () => {
  const getCurrentCombo = async () => {
    const { data } = await $api.get<{ sequence: string[] }>(
      '/api/secret-combos/current'
    );

    return data;
  };

  return {
    getCurrentCombo,
  };
};

export const combosService = comboApi();
