// Custom components
import TooltipHorizon from 'components/tooltip';
import { InputHTMLAttributes, ReactElement, forwardRef } from 'react';

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  extra: string;
  placeholder: string;
  variant: string;
  state?: string;
  disabled?: boolean;
  type?: string;
  tooltip?: ReactElement;
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref) => {
    const {
      label,
      id,
      extra,
      type,
      placeholder,
      variant,
      state,
      disabled,
      tooltip,
      ...inputProps
    } = props;

    return (
      <div className={`${extra}`}>
        {!!label && (
          <label
            htmlFor={id}
            className={`text-medium text-navy-700 dark:text-white ${
              variant === 'auth' ? 'ml-0 font-medium' : 'ml-3 font-bold'
            }`}
          >
            {label}
            {tooltip && (
              <TooltipHorizon
                extra=""
                placement="top"
                trigger={
                  <span className="inline-flex justify-center items-center ml-2 min-w-4 w-4 h-4 border border-gray-500 rounded-full text-xs text-gray-500">
                    ?
                  </span>
                }
                content={tooltip}
              />
            )}
          </label>
        )}
        <input
          disabled={disabled}
          type={type}
          id={id}
          placeholder={placeholder}
          className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none transition-opacity duration-300 ${
            disabled === true
              ? 'opacity-50'
              : state === 'error'
                ? 'border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400'
                : state === 'success'
                  ? 'border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400'
                  : 'border-gray-200 dark:!border-white/10 dark:text-white'
          }`}
          ref={ref}
          {...inputProps}
        />
      </div>
    );
  }
);

export default InputField;
