import {
  useFormContext,
  Controller,
  ControllerRenderProps,
} from 'react-hook-form';
import InputField from 'components/fields/InputField';
import TextArea from 'components/fields/TextArea';
import { CampaignFormScheme } from '../../scheme/taksForm';
import getCountries from 'views/services/getCountries';
import getLanguages from 'views/services/getLanguages';
import { useState, useEffect } from 'react';
import { campaignService } from 'views/services/campaignService';
import { Button } from 'components/button';
import { MdAdd } from 'react-icons/md';
import { Campaign, CampaignTask, GendersEnum } from 'views/types/tasks';
import { CampaignTaskItem } from '../CampaignTask';
import Switch from 'components/switch';
import { preventScrollOnNumberInput } from 'utils/input';
import { utmService } from 'views/services/utmService';
import Select from 'components/fields/Select';

type CampaignFieldNames = keyof CampaignFormScheme;
type CampaignFieldNamesFormFieldName = `${CampaignFieldNames}`;

type TTaskFormProps = {
  isEdit: boolean;
  campaign: Campaign;
  setIsChangedImage: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTask: React.Dispatch<React.SetStateAction<CampaignTask>>;
};

export const CampaignForm = ({
  isEdit,
  campaign,
  setIsChangedImage,
  setIsOpenTaskModal,
  setTask,
}: TTaskFormProps) => {
  const form = useFormContext<CampaignFormScheme>();
  const [countries, setCountries] = useState<
    { id: string; code: string; name: string }[]
  >([]);
  const [languages, setLanguages] = useState<{ code: string }[]>([]);

  const getIsAllCountriesSelected = () => {
    if (!countries.length) return false;

    return countries.every(
      // @ts-expect-error its exist
      country => form.getValues('targetCountries')[country.id]
    );
  };
  const getIsAllLanguagesSelected = () => {
    if (!languages.length) return false;

    return languages.every(
      // @ts-expect-error its exist
      language => form.getValues('targetLanguages')[language.code]
    );
  };
  const [isAllcountriesSelected, setIsAllcountriesSelected] = useState(
    getIsAllCountriesSelected()
  );
  const [isAllLanguagesSelected, setIsAllLanguagesSelected] = useState(
    getIsAllLanguagesSelected()
  );

  const [isLoading, setIsLoading] = useState(true);
  const [imageURL, setImageURL] = useState('');
  const [date, setDate] = useState<string>();
  const [utmSources, setUtmSources] = useState([]);

  const image = imageURL.length ? imageURL : isEdit ? campaign.imageUrl : '';

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const fetchedCountries = await getCountries();
        const fetchedLanguages = await getLanguages();
        setCountries(fetchedCountries);
        setLanguages(fetchedLanguages);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    utmService
      .getPaginatedUTMSources({ pageSize: 1000 })
      .then(resp => resp && setUtmSources(resp.data));
  }, []);

  if (!form) {
    throw new Error('useFormContext must be used inside a FormProvider');
  }

  const uploadImage = async (image: File) => {
    if (!image) return;

    try {
      const formData = new FormData();
      formData.append('files', image, image.name);
      const uploadedImageUrl = await campaignService.uploadAdminURL(formData);
      setImageURL(uploadedImageUrl);
      form.setValue('imageUrl', uploadedImageUrl);
      setIsChangedImage(true);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    uploadImage(file);
  };

  const handleOpen = (isOpen: boolean, task?: CampaignTask) => {
    setIsOpenTaskModal(isOpen);
    setIsChangedImage(true);
    if (task) {
      setTask(task);
    }
  };

  const handleChooseDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
    e.target.blur();
  };

  const handleAllCountriesChange = () => {
    const isSelected = getIsAllCountriesSelected();

    const allSelectedCountries = countries.reduce(
      (acc, country) => ({
        ...acc,
        [country.id]: !isSelected,
      }),
      {}
    );

    form.setValue('targetCountries', allSelectedCountries, {
      shouldDirty: true,
    });
    setIsAllcountriesSelected(!isSelected);
  };

  const handleAllLanguagesChange = () => {
    const isSelected = getIsAllLanguagesSelected();

    const allSelectedLanguages = languages.reduce(
      (acc, language) => ({
        ...acc,
        [language.code]: !isSelected,
      }),
      {}
    );

    form.setValue('targetLanguages', allSelectedLanguages, {
      shouldDirty: true,
    });
    setIsAllLanguagesSelected(!isSelected);
  };

  const handleCountrySelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<CampaignFormScheme, keyof CampaignFormScheme>
  ) => {
    field.onChange(e.target.checked);
    setIsAllcountriesSelected(getIsAllCountriesSelected());
  };

  const handleLanguageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<CampaignFormScheme, keyof CampaignFormScheme>
  ) => {
    field.onChange(e.target.checked);
    setIsAllLanguagesSelected(getIsAllLanguagesSelected());
  };

  useEffect(() => {
    setIsAllcountriesSelected(getIsAllCountriesSelected());
    setIsAllLanguagesSelected(getIsAllLanguagesSelected());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, languages]);

  return (
    <>
      <fieldset className="mb-[15px] flex flex-col">
        <div className={`w-full mb-3`}>
          <label
            htmlFor="image"
            className={`text-sm text-navy-700 dark:text-white ${'ml-1.5 font-medium'}`}
          >
            Image URL
          </label>

          <input
            type="file"
            onChange={handleImageChange}
            required={isEdit ? false : true}
            id="image"
            accept=".jpg,.jpeg,.png"
            placeholder="Provide image URL"
            className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none transition-opacity duration-300 ${
              form.formState.errors.imageUrl
                ? 'border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400'
                : 'border-gray-200 dark:!border-white/10 dark:text-white'
            }`}
          />

          {image && (
            <div className="mt-4">
              <img
                src={image}
                alt="Uploaded"
                className="w-20 h-20 object-cover"
              />
            </div>
          )}
        </div>
        {form.formState.errors.imageUrl && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.imageUrl.message}
          </span>
        )}
      </fieldset>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.title ? 'error' : undefined}
          extra="w-full mb-3"
          label="Title"
          placeholder="Enter campaign title"
          id="title"
          type="string"
          {...form.register('title', {
            required: 'This field is required',
          })}
        />
        {form.formState.errors.title && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.title.message}
          </span>
        )}
      </fieldset>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.rewardAmount ? 'error' : undefined}
          extra="w-full mb-3"
          label="Rewards amount"
          placeholder="Enter rewards amount"
          id="rewardAmount"
          onWheel={preventScrollOnNumberInput}
          {...form.register('rewardAmount', {
            required: 'This field is required',
            pattern: {
              value: /^(?!0$)[0-9]+([.,]{1}[0-9]+)?$/g,
              message: 'Invalid number format',
            },
          })}
        />
        {form.formState.errors.rewardAmount && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.rewardAmount.message}
          </span>
        )}
      </fieldset>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.approximateTime ? 'error' : undefined}
          extra="w-full mb-3"
          label="Approximate time"
          placeholder="Enter approximate time for complete this campaign"
          id="approximateTime"
          type="number"
          onWheel={preventScrollOnNumberInput}
          {...form.register('approximateTime', {
            required: 'This field is required',
            pattern: {
              value: /^(?!0$)[0-9]+([.,]{1}[0-9]+)?$/g,
              message: 'Invalid number format',
            },
          })}
        />
        {form.formState.errors.approximateTime && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.approximateTime.message}
          </span>
        )}
      </fieldset>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.endDate ? 'error' : undefined}
          extra="w-full mb-3"
          label="End date"
          placeholder="Enter end date for this campaign"
          id="endDate"
          type="date"
          value={date}
          onChange={handleChooseDate}
          onWheel={preventScrollOnNumberInput}
          {...form.register('endDate', {
            required: 'This field is required',
          })}
        />
        {form.formState.errors.endDate && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.endDate.message}
          </span>
        )}
      </fieldset>

      <div className="flex flex-col">
        <fieldset className="mb-[15px] flex flex-col">
          <TextArea
            variant="auth"
            state={form.formState.errors.description ? 'error' : undefined}
            extra="w-full mb-3"
            label="Description"
            placeholder="Enter campaign description"
            id="description"
            {...form.register('description', {
              required: 'This field is required',
            })}
          />
          {form.formState.errors.description && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.description.message}
            </span>
          )}
        </fieldset>
      </div>

      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={
            form.formState.errors?.usersCompletedCountMax ? 'error' : undefined
          }
          extra="w-full mb-3"
          label="Maximum users limit"
          placeholder="Enter the maximum number of users who can complete the task"
          id="usersCompletedCountMax"
          type="number"
          onWheel={preventScrollOnNumberInput}
          {...form.register('usersCompletedCountMax', {
            pattern: {
              value: /^[0-9]+([.,]{1}[0-9]+)?$/g,
              message: 'Invalid number format',
            },
          })}
        />

        {form.formState.errors?.usersCompletedCountMax && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors?.usersCompletedCountMax.message}
          </span>
        )}
      </fieldset>

      <div className="flex gap-8">
        <fieldset className="mb-[15px] flex flex-col">
          <div className="flex items-center mb-2 ">
            <label className="text-sm font-medium text-navy-700 dark:text-white">
              Target Countries
            </label>

            <label
              id="select-all_countries"
              className="ml-4 text-sm flex items-center justify-center"
            >
              <input
                type="checkbox"
                className="mr-1 h-3 w-3"
                onChange={handleAllCountriesChange}
                checked={isAllcountriesSelected}
              />
              Select All
            </label>
          </div>
          <div className="space-y-2 max-h-[300px] overflow-y-auto">
            {isLoading && <span>Loading...</span>}
            {!isLoading &&
              countries.map(country => (
                <label key={country.id} className="flex items-center">
                  <Controller
                    name={
                      `targetCountries.${country.id}` as CampaignFieldNamesFormFieldName
                    }
                    control={form.control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        id={`country-${country.id}`}
                        checked={!!field.value}
                        onChange={e => handleCountrySelect(e, field)}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                  <span className="ml-2">{country.name}</span>
                </label>
              ))}
          </div>
        </fieldset>

        <fieldset className="mb-[15px] flex flex-col">
          <div className="flex items-center mb-2 ">
            <label className="text-sm font-medium text-navy-700 dark:text-white">
              Target Languages
            </label>

            <label
              id="select-all_languages"
              className="ml-4 text-sm flex items-center justify-center"
            >
              <input
                type="checkbox"
                className="mr-1 h-3 w-3"
                onChange={handleAllLanguagesChange}
                checked={isAllLanguagesSelected}
              />
              Select All
            </label>
          </div>
          <div className="space-y-2 max-h-[300px] overflow-y-auto">
            {isLoading && <span>Loading...</span>}
            {!isLoading &&
              languages.map(language => (
                <label key={language.code} className="flex items-center">
                  <Controller
                    name={
                      `targetLanguages.${language.code}` as CampaignFieldNamesFormFieldName
                    }
                    control={form.control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        id={`language-${language.code}`}
                        checked={!!field.value}
                        onChange={e => handleLanguageChange(e, field)}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                  <span className="ml-2">{language.code}</span>
                </label>
              ))}
          </div>
        </fieldset>
      </div>

      <div className="flex gap-8">
        <fieldset className="mb-[15px] flex flex-col">
          <label className="mb-2 text-sm font-medium text-navy-700 dark:text-white">
            Target UTM Sources
          </label>
          <div className="space-y-2 max-h-[300px] overflow-y-auto">
            {isLoading && <span>Loading...</span>}
            {!isLoading &&
              utmSources &&
              utmSources.map(utmSource => (
                <label key={utmSource.id} className="flex items-center">
                  <Controller
                    name={
                      `targetUtmSources.${utmSource.id}` as CampaignFieldNamesFormFieldName
                    }
                    control={form.control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        id={`utmSource-${utmSource.id}`}
                        checked={!!field.value}
                        onChange={e => field.onChange(e.target.checked)}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                  <span className="ml-2">
                    {`${utmSource.name} (${utmSource.amountOfLeads} user${+utmSource.amountOfLeads !== 1 ? 's' : ''})`}
                  </span>
                </label>
              ))}
          </div>
        </fieldset>

        <fieldset className="mb-[15px] flex flex-col w-max">
          <Select
            variant="auth"
            state={form.formState.errors.targetGenders ? 'error' : undefined}
            extra="w-full mb-3"
            label="Gender targeting:"
            id="gender"
            {...form.register('targetGenders')}
          >
            <option value="">Select gender</option>
            {Object.values(GendersEnum).map(gender => (
              <option key={gender} value={gender}>
                {gender}
              </option>
            ))}
          </Select>
          {form.formState.errors.targetGenders && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.targetGenders.message}
            </span>
          )}
        </fieldset>
      </div>

      <fieldset className="mb-[15px] ml-1.5 flex">
        <label
          htmlFor="isTgPremiumOnly"
          className="mb-2 mr-auto text-sm font-medium text-navy-700 dark:text-white"
        >
          Campaign for TG Premium users only
        </label>
        <Switch id="isTgPremiumOnly" {...form.register('isTgPremiumOnly')} />
      </fieldset>
      {form.formState.errors.isTgPremiumOnly && (
        <span className="text-sm text-red-500 dark:text-red-400">
          {form.formState.errors.isTgPremiumOnly.message}
        </span>
      )}
      <fieldset className="mb-[15px] ml-1.5 flex">
        <label
          htmlFor="isDuckPremiumOnly"
          className="mb-2 mr-auto text-sm font-medium text-navy-700 dark:text-white"
        >
          Campaign for Duck Premium subscribers
        </label>
        <Switch
          id="isDuckPremiumOnly"
          {...form.register('isDuckPremiumOnly')}
        />
      </fieldset>
      {form.formState.errors.isDuckPremiumOnly && (
        <span className="text-sm text-red-500 dark:text-red-400">
          {form.formState.errors.isDuckPremiumOnly.message}
        </span>
      )}
      <fieldset className="mb-[15px] ml-1.5 flex">
        <label
          htmlFor="withNotification"
          className="mb-2 mr-auto text-sm font-medium text-navy-700 dark:text-white"
        >
          Send push notification to bot about new company
        </label>
        <Switch id="withNotification" {...form.register('withNotification')} />
      </fieldset>
      {form.formState.errors.withNotification && (
        <span className="text-sm text-red-500 dark:text-red-400">
          {form.formState.errors.withNotification.message}
        </span>
      )}
      <fieldset className="mb-[15px] ml-1.5 flex">
        <label
          htmlFor="onlyForIos"
          className="mb-2 mr-auto text-sm font-medium text-navy-700 dark:text-white"
        >
          Only for IOS users
        </label>
        <Switch id="onlyForIos" {...form.register('onlyForIos')} />
      </fieldset>
      {form.formState.errors.onlyForIos && (
        <span className="text-sm text-red-500 dark:text-red-400">
          {form.formState.errors.onlyForIos.message}
        </span>
      )}

      {isEdit && (
        <div>
          <p>Tasks:</p>
          <div className="flex flex-col mb-2 mt-2 gap-2">
            {!!campaign?.tasks &&
              campaign.tasks.map(taskItem => (
                <CampaignTaskItem
                  task={taskItem}
                  key={taskItem.id}
                  handleOpenTaskModal={handleOpen}
                />
              ))}
          </div>

          <Button onClick={() => handleOpen(true)}>
            <MdAdd />
            Add task
          </Button>
        </div>
      )}
    </>
  );
};
