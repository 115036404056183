import Card from 'components/card';
import { useEffect, useMemo, useState } from 'react';
import { ButtonSM } from '../../../../../components/button/Small';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { Button } from 'components/button';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdAdd } from 'react-icons/md';
import { useDebounceValue } from 'usehooks-ts';
import { Campaign } from 'views/types/tasks';
import { TaskDelete } from '../CampaignDelete';
import { campaignService } from 'views/services/campaignService';
import { campaignStore } from 'views/store/useCampaignStore';
import { TaskActivate } from '../CampaignActivate';
import { CampaignDialog } from '../CampaignDialog';
import { useAnaliticsStore } from 'views/store/useAnaliticsStore';

function TasksTable() {
  const {
    setId: setAnaliticsTaskId,
    setType: setAnaliticsType,
    setTitle: setAnalitictTitle,
  } = useAnaliticsStore();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { campaignList: tableData } = campaignStore();
  const [filteredData, setFilteredData] = useState([]);

  const refreshCampaignList = async () => {
    try {
      await campaignService.getCampaigns();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshCampaignList();
  }, []);

  useEffect(() => {
    setFilteredData(
      tableData.filter(campaign =>
        campaign.title
          .toLowerCase()
          .trim()
          .includes(search.toLowerCase().trim())
      )
    );
  }, [search, tableData]);

  const handleAnaliticsModal = (id: string | null, title: string) => {
    setAnaliticsTaskId(id);
    setAnalitictTitle(title);
    setAnaliticsType('campaign');
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('title', {
        id: 'title',
        enableSorting: false,
        header: () => (
          <p className="w-[200px] min-w-[200px] text-sm font-bold text-gray-600 dark:text-white">
            Title
          </p>
        ),
        cell: info => (
          <p className="w-[200px] min-w-[200px] truncate text-sm font-bold text-navy-700 dark:text-white">
            {info.cell.getValue()}
          </p>
        ),
      }),
      columnHelper.accessor('rewardAmount', {
        id: 'awardPoints',
        enableSorting: false,
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            Award (points)
          </p>
        ),
        cell: info => (
          <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
            {info.cell.getValue()}
          </p>
        ),
      }),
      columnHelper.accessor('targetedUsers', {
        id: 'completedUsersCount',
        enableSorting: false,
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            Users Completed / Users Total
          </p>
        ),
        cell: info => {
          const usersCompletedCount = info.row.original.usersCompletedCount;
          const usersTotalCount = info.row.original.targetedUsers;

          return (
            <p className="w-[150px] min-w-[150px] text-sm font-bold text-navy-700 dark:text-white">
              {usersCompletedCount} / {usersTotalCount}
            </p>
          );
        },
      }),

      columnHelper.accessor('targetLanguages', {
        id: 'languageGroup',
        enableSorting: false,
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            Language Group
          </p>
        ),
        cell: info => {
          const languages = info.cell.getValue() as string[] | null;
          const displayValue =
            languages && Array.isArray(languages) && languages.length > 0
              ? languages.join(', ')
              : 'N/A';

          return (
            <p className="w-[150px] min-w-[150px] text-sm font-bold text-navy-700 dark:text-white">
              {displayValue}
            </p>
          );
        },
      }),

      columnHelper.display({
        id: 'actions',
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            Actions
          </p>
        ),
        cell: info => (
          <div className="flex gap-2 font-bold">
            <CampaignDialog campaign={info.row.original} formType="campaign">
              <ButtonSM variant="primary">Edit</ButtonSM>
            </CampaignDialog>
            <TaskDelete campaign={info.row.original} />
            <TaskActivate campaign={info.row.original} />
            <ButtonSM
              variant="primary"
              onClick={() =>
                handleAnaliticsModal(
                  info.row.original.id,
                  info.row.original.title
                )
              }
            >
              Analytics
            </ButtonSM>
          </div>
        ),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [data] = useDebounceValue(filteredData ?? [], 100);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <div className="mb-8 mt-8 flex items-center justify-between">
        <div className="flex gap-2 ml-auto">
          <CampaignDialog formType="campaign">
            <Button>
              <MdAdd />
              Create campaign
            </Button>
          </CampaignDialog>

          <CampaignDialog formType="message">
            <Button>
              <MdAdd />
              Create Push Message
            </Button>
          </CampaignDialog>
        </div>
      </div>
      <Card extra={'w-full h-[800px] md:h-[500px] sm:overflow-auto px-6 pb-6'}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Campaign Dashboard
          </div>
          <input
            type="text"
            placeholder="Search by campaign title"
            className="block min-h-7 w-full grow rounded-full bg-lightPrimary px-4 text-base font-merow rounded-full bg-lightPrimary px-4 text-base font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:h-full sm:w-fit sm:max-w-96"
            value={search}
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
        </header>

        <div className="mt-8 overflow-x-auto xl:overflow-x-hidden">
          <table className="w-full">
            <thead className="sticky top-0 z-10">
              {table.getHeaderGroups().map(headerGroup => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className={clsx(
                          'bg-white pb-2 pr-4 pt-4 text-start dark:bg-navy-800',
                          header.column.getCanSort() && 'cursor-pointer'
                        )}
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td
                    className="absolute left-1/2 -translate-x-1/2"
                    colSpan={8}
                  >
                    <p className="flex justify-center py-10">
                      <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
                    </p>
                  </td>
                </tr>
              )}
              {!isLoading && (tableData.length ?? 0) <= 0 && (
                <tr>
                  <td
                    className="absolute left-1/2 -translate-x-1/2"
                    colSpan={8}
                  >
                    <p className="py-10 text-center font-bold uppercase">
                      No data
                    </p>
                  </td>
                </tr>
              )}
              {!isLoading &&
                !!tableData &&
                tableData.length > 0 &&
                table.getRowModel().rows.map(row => {
                  return (
                    <tr
                      key={row.id}
                      className="[&_td]:odd:bg-gray-50 [&_td]:odd:dark:bg-navy-900"
                    >
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[50px] border-white/0 py-2 pr-4 first:rounded-l-lg last:rounded-r-lg last:pr-0"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Card>
    </>
  );
}

export default TasksTable;
const columnHelper = createColumnHelper<Campaign>();
