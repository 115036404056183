import * as Dialog from '@radix-ui/react-dialog';
import { Button } from 'components/button';
import { DangerButton } from 'components/button/Danger';
import { SuccessButton } from 'components/button/Success';
import { useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import useSWR from 'swr';
import { requestsService } from 'views/services/requestsService';

interface UserDialogProps {
  requestId: string;
  children: React.ReactNode;
  onOpenChange: (isOpen: boolean) => void;
  onApproveOrReject: () => Promise<unknown>;
}

export function RequestDialog({
  requestId,
  onOpenChange,
  children,
  onApproveOrReject,
}: UserDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { data: request, isLoading } = useSWR(
    isOpen ? `/request/${requestId}` : null,
    () => requestsService.getRequest(requestId)
  );

  const handleApprove = async () => {
    if (
      window.confirm(
        `Are you sure you want to approve request for ${request.userTask.user.username}?`
      )
    ) {
      await requestsService.approveRequest(request.userTask.id);
      await onApproveOrReject();
    }
  };

  const handleReject = async () => {
    if (
      window.confirm(
        `Are you sure you want to reject request for ${request.userTask.user.username}?`
      )
    ) {
      await requestsService.rejectRequest(request.userTask.id);
      await onApproveOrReject();
    }
  };

  return (
    <Dialog.Root
      modal
      open={isOpen}
      onOpenChange={isOpen => {
        setIsOpen(isOpen);
        onOpenChange(isOpen);
      }}
    >
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
        <Dialog.Content
          aria-describedby="request-dialog-title"
          onInteractOutside={e => {
            if ((e.target as HTMLElement).closest('.chakra-toast')) {
              e.preventDefault();
            }
          }}
          className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[650px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none dark:bg-gray-800 dark:text-white"
        >
          <Dialog.Title
            id="request-dialog-title"
            className="text-mauve12 mb-10 text-2xl font-medium"
          >
            {isLoading
              ? 'Loading...'
              : `Request from @${request?.userTask.user.username}`}
          </Dialog.Title>
          {isLoading && (
            <div className="flex h-[292px] items-center justify-center">
              <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
            </div>
          )}
          {!isLoading && request && (
            <>
              <div className="max-h-[calc(85vh_-_227px)] min-h-[292px] grow overflow-x-auto px-px">
                <ul className="flex flex-col gap-4 text-lg">
                  <li>
                    <b>STATUS:</b>{' '}
                    <span className="text-base text-gray-500">
                      {request.status}
                    </span>
                  </li>
                  <li>
                    <b>Task name:</b>{' '}
                    <span className="text-base text-gray-500">
                      {request.userTask.task.title}
                    </span>
                  </li>
                  <li>
                    <b>Description:</b>{' '}
                    <span className="text-base text-gray-500">
                      {request.userTask.task.description}
                    </span>
                  </li>
                </ul>
                <div className="mt-4">
                  <b className="mb-2 block text-lg">Results:</b>{' '}
                  {(!request.snapshotsUrls ||
                    request.snapshotsUrls?.length <= 0) && (
                    <>
                      <span className="text-base text-gray-500">
                        No result yet
                      </span>
                    </>
                  )}
                  {request.snapshotsUrls?.length > 0 &&
                    request.snapshotsUrls?.map((imgSrc, i) => (
                      <a
                        href={imgSrc}
                        className="flex outline-none"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          key={i}
                          width={400}
                          src={imgSrc}
                          className="w-full max-w-[300px] object-contain outline-none"
                          alt="requestImage"
                        />
                      </a>
                    ))}
                </div>
              </div>
            </>
          )}
          <div className="mt-[10px] flex flex-col gap-2">
            <div className="flex gap-2">
              <SuccessButton
                className="w-full justify-center"
                onClick={handleApprove}
              >
                Approve
              </SuccessButton>
              <DangerButton
                className="w-full justify-center"
                onClick={handleReject}
              >
                Reject
              </DangerButton>
            </div>
            <Dialog.Close asChild>
              <Button className="w-full justify-center">Close</Button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button
              type="button"
              className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
              aria-label="Close"
            >
              <MdClose />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
