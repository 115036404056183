// Admin Imports
import MainDashboard from 'views/admin/default';

// Auth Imports
import SignIn from 'views/auth/SignIn';

// Icon Imports
import {
  MdGroup,
  MdLock,
  MdOpenInBrowser,
  MdOutlineTask,
  MdTaskAlt,
} from 'react-icons/md';
import RequestsView from 'views/admin/requests';
import TasksView from 'views/admin/marketplace';
import CampaignView from 'views/admin/campaigns';
import UTMSourcesView from 'views/admin/utm_sources';

const routes = [
  {
    name: 'Users Dashboard',
    layout: '/admin',
    path: 'default',
    icon: <MdGroup className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: 'Task Page',
    layout: '/admin',
    path: 'tasks',
    icon: <MdOutlineTask className="h-6 w-6" />,
    component: <TasksView />,
    secondary: true,
  },
  {
    name: 'Campaign Page',
    layout: '/admin',
    path: 'campaign',
    icon: <MdOutlineTask className="h-6 w-6" />,
    component: <CampaignView />,
    secondary: true,
  },
  // {
  //   name: 'Cryptocurrencies',
  //   layout: '/admin',
  //   icon: <MdToken className="h-6 w-6" />,
  //   path: 'cryptocurrencies',
  //   component: <TokensView />,
  // },
  {
    name: 'Requests',
    layout: '/admin',
    icon: <MdTaskAlt className="h-6 w-6" />,
    path: 'requests',
    component: <RequestsView />,
  },
  {
    name: 'UTM Sources',
    layout: '/admin',
    path: 'utm-sources',
    icon: <MdOpenInBrowser className="h-6 w-6" />,
    component: <UTMSourcesView />,
    secondary: true,
  },
  {
    name: 'Sign In',
    layout: '/auth',
    path: 'sign-in',
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
];
export default routes;
