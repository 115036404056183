import { ButtonSM } from 'components/button/Small';
import { useState } from 'react';
import { campaignService } from 'views/services/campaignService';
import { tasksService, toast } from 'views/services/tasksService';
import { Campaign, TaskType } from 'views/types/tasks';

export type TTaskDeleteProps = {
  campaign: Campaign;
};

export const TaskActivate = ({ campaign }: TTaskDeleteProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const hasQuiz = campaign?.tasks
    ? campaign?.tasks?.find(task => task.type === TaskType.QUIZ)
    : null;

  const handleActivate = async () => {
    if (!hasQuiz) {
      toast({
        title: 'Campaign must contain QUIZ',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      });

      return;
    }
    setIsSubmitting(true);
    try {
      await campaignService.updateCampaign(
        { ...campaign, isActive: !campaign.isActive },
        campaign.id,
        true
      );
      if (campaign.withNotification && !campaign.isActive) {
        await tasksService.createMessage(
          `New campaign ${campaign.title} now live in Duck`,
          campaign.id
        );
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ButtonSM
      variant={campaign.isActive ? 'active' : 'inactive'}
      disabled={isSubmitting}
      onClick={handleActivate}
    >
      {campaign.isActive ? 'Deactivate' : 'Activate'}
    </ButtonSM>
  );
};
