import { formatNumber } from 'utils/formatNumber';
import { TAnaliticsData } from 'views/types/analitics';
import { UserCountAndPercent } from './UserCountAndPercent';

export const renderGenderData = ({ genderData }: TAnaliticsData) => (
  <div className="flex gap-20 w-full">
    <h3 className="min-w-[100px] font-bold">Gender:</h3>

    <div className="flex flex-col gap-1">
      <p>
        <span className="mr-4 font-bold">Male:</span>
        {formatNumber(genderData.maleCount)} (
        {formatNumber(genderData.malePercent)}%)
      </p>
      <p>
        <span className="mr-4 font-bold">Female:</span>
        {formatNumber(genderData.femaleCount)} (
        {formatNumber(genderData.femalePercent)}%)
      </p>
      <p>
        <span className="mr-4 font-bold">Other:</span>
        {formatNumber(genderData.otherGenderCount)} (
        {formatNumber(genderData.otherGenderPercent)}%)
      </p>
    </div>
  </div>
);

export const renderCountriesData = ({ countriesData }: TAnaliticsData) => (
  <div className="flex gap-20 w-full">
    <h3 className="min-w-[100px] font-bold">Countries:</h3>

    <div className="grid gap-4 grid-cols-2">
      {countriesData.map((country, index) => (
        <div key={index} className="w-[200px]">
          <p className="font-bold">{country.name}</p>
          <UserCountAndPercent
            count={country.usersCount}
            percent={country.usersPercent}
          />
        </div>
      ))}
    </div>
  </div>
);

export const renderLanguagesData = ({ languagesData }: TAnaliticsData) => (
  <div className="flex gap-20 w-full">
    <h3 className="min-w-[100px] font-bold">Languages:</h3>

    <div className="grid gap-4 grid-cols-2">
      {languagesData.map((language, index) => (
        <div key={index} className="w-[200px]">
          <p className="font-bold uppercase">{language.name}</p>
          <UserCountAndPercent
            count={language.usersCount}
            percent={language.usersPercent}
          />
        </div>
      ))}
    </div>
  </div>
);

export const renderPremiumPlansData = ({
  duckPremiumData,
  tgPremiumData,
  completedUsersCount,
}: TAnaliticsData) => (
  <div>
    <div className="flex gap-20 w-full">
      <h3 className="min-w-[100px] font-bold">Premium:</h3>
      <div className="grid gap-4 grid-cols-2">
        <div className="w-[200px]">
          <h4 className="font-bold">Duck premium</h4>
          <UserCountAndPercent
            count={duckPremiumData.usersCount}
            percent={duckPremiumData.usersPercent}
          />
        </div>

        <div className="w-[200px]">
          <h4 className="font-bold">TG premium</h4>
          <UserCountAndPercent
            count={tgPremiumData.usersCount}
            percent={tgPremiumData.usersPercent}
          />
        </div>

        <div className="w-[200px]">
          <h4 className="font-bold">Without Duck premium</h4>
          <UserCountAndPercent
            count={completedUsersCount - duckPremiumData.usersCount}
            percent={100 - duckPremiumData.usersPercent}
          />
        </div>

        <div className="w-[200px]">
          <h4 className="font-bold">Without TG premium</h4>
          <UserCountAndPercent
            count={completedUsersCount - tgPremiumData.usersCount}
            percent={100 - tgPremiumData.usersPercent}
          />
        </div>
      </div>
    </div>
  </div>
);

export const renderUtmSourcesData = ({ utmSourcesData }: TAnaliticsData) => (
  <div className="flex gap-20 w-full">
    <h3 className="min-w-[100px] font-bold">UTM Sources:</h3>

    <div className="grid gap-4 grid-cols-2">
      {utmSourcesData.map((source, index) => (
        <div key={index} className="w-[200px]">
          <p className="font-bold">{source.name}</p>
          <UserCountAndPercent
            count={source.usersCount}
            percent={source.usersPercent}
          />
        </div>
      ))}
    </div>
  </div>
);

export const renderWalletsData = ({
  walletsData,
  completedUsersCount,
}: TAnaliticsData) => (
  <div className="flex gap-20 w-full">
    <h3 className="min-w-[100px] font-bold">Wallets:</h3>
    <div className="grid gap-4 grid-cols-2">
      <div className="flex flex-col gap-1 w-[200px]">
        <p className="font-bold">With wallet:</p>
        <UserCountAndPercent
          count={walletsData.usersCount}
          percent={walletsData.usersPercent}
        />
      </div>

      <div className="flex flex-col gap-1 w-[200px]">
        <p className="font-bold">Without wallet:</p>
        <UserCountAndPercent
          count={completedUsersCount - walletsData.usersCount}
          percent={100 - walletsData.usersPercent}
        />
      </div>
    </div>
  </div>
);
