import * as Dialog from '@radix-ui/react-dialog';
import { MdClose } from 'react-icons/md';

import clsx from 'clsx';
import { TAnaliticsData } from 'views/types/analitics';
import { useAnaliticsStore } from 'views/store/useAnaliticsStore';
import {
  renderCountriesData,
  renderGenderData,
  renderLanguagesData,
  renderPremiumPlansData,
  renderUtmSourcesData,
  renderWalletsData,
} from './helpers';
import { useEffect, useState } from 'react';
import { tasksService } from 'views/services/tasksService';
import { campaignService } from 'views/services/campaignService';

export const AnaliticsDialog = () => {
  const { id, type, setId, loading, setLoading, title, setTitle } =
    useAnaliticsStore();
  const [data, setData] = useState<TAnaliticsData | null>(null);

  useEffect(() => {
    if (id) {
      if (type === 'task') {
        setLoading(true);
        tasksService
          .getTaskStats(id)
          .then(setData)
          .finally(() => setLoading(false));
      } else {
        setLoading(true);
        campaignService
          .getCampaignAnalytics(id)
          .then(setData)
          .finally(() => setLoading(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleClose = () => {
    setData(null);
    setId(null);
    setTitle('');
  };

  return (
    <>
      <Dialog.Root open={!!id} onOpenChange={handleClose} modal>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
          <Dialog.Content
            onInteractOutside={e => {
              if ((e.target as HTMLElement).closest('.chakra-toast')) {
                e.preventDefault();
              }
            }}
            className={clsx(
              'data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[800px]',
              'translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px]',
              'shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]',
              'focus:outline-none dark:bg-gray-800 dark:text-white',
              'flex'
            )}
          >
            {loading && <p>Loading...</p>}
            {!data && !loading && <p>No data</p>}
            {data && (
              <div className="w-full flex flex-col gap-8 max-h-[80svh] overflow-y-auto  mt-4">
                <div className="mb-8">
                  <h2 className="text-2xl font-semibold mb-2 text-3xl">
                    Analytics:
                  </h2>
                  <h3 className="text-2xl font-semibold mb-8 text-lg">
                    <span className="uppercase mr-3">{type}:</span>
                    {title}
                  </h3>

                  <div className="flex gap-8">
                    <div className="flex gap-2">
                      <h3 className="min-w-[100px] font-bold">
                        Users available:
                      </h3>
                      <p>{data.availableUsersCount}</p>
                    </div>

                    <div className="flex gap-2">
                      <h3 className="min-w-[100px] font-bold">
                        Users completed:
                      </h3>
                      <p>{data.completedUsersCount}</p>
                    </div>
                  </div>
                </div>

                {renderGenderData(data)}
                {renderCountriesData(data)}
                {renderLanguagesData(data)}
                {renderPremiumPlansData(data)}
                {renderUtmSourcesData(data)}
                {renderWalletsData(data)}
              </div>
            )}
            <Dialog.Close asChild>
              <button
                id="task-modal-close-btn"
                type="button"
                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
