import { unionWith } from 'lodash';
import { Paginated } from 'types/paginated';
import { User, UserStatistic } from '../types/typeUser';
import { $api } from './interceptor';
import { toast } from 'App';

const usersApi = () => {
  const getUsersStatistic = async () => {
    const response = await $api.get<UserStatistic[]>('api/users/statistic');
    if (response.status === 200) {
      const total = response.data.reduce(
        (acc, role) => acc + Number(role.count),
        0
      );
      const kol = response.data.find(role => role.role === 'KOL');
      const users = response.data.find(role => role.role === 'USER');
      const admins = response.data.find(role => role.role === 'ADMIN');

      return {
        totalUsersCount: total,
        kolUsersCount: kol ? +kol.count : 0,
        basicUsersCount: users ? +users.count : 0,
        adminUsersCount: admins ? +admins.count : 0,
      };
    }
  };

  const upgradeUserIncomePercent = async (
    userId: string,
    customIncomePercent: number
  ) => {
    try {
      await $api.put(`api/users/upgrade/${userId}`, {
        customIncomePercent,
      });
      toast({
        title: 'User has been updated successfully',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (err) {
      console.log(err);
      toast({
        title: 'Error while updating user',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const upgradeUserDuckPremiumStatus = async (
    userId: string,
    everlastingDuckPremium: boolean
  ) => {
    try {
      await $api.put(`api/users/upgrade/${userId}`, {
        everlastingDuckPremium,
      });
      toast({
        title: 'User has been updated successfully',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (err) {
      console.log(err);
      toast({
        title: 'Error while updating user',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const getUsersList = async () => {
    const { data } = await $api.get<Paginated<User[]>>('api/users/list');
    return data.data;
  };

  const getPaginatedUsersList = async ({
    page = 0,
    pageSize = 10,
    searchValue,
    sortingValue,
    utmSource,
  }: {
    page?: number;
    searchField?: string;
    pageSize?: number;
    searchValue: string;
    sortingValue: string;
    utmSource: string;
  }): Promise<Paginated<User>> => {
    let search = searchValue;

    let filters_is_premium;
    let filters_date;

    if (search === 'Yes') {
      filters_is_premium = '$eq:true';
      search = '';
    } else if (search === 'No') {
      filters_is_premium = '$null';
      search = '';
    }

    if (utmSource == 'Another traffic source') {
      utmSource = '$null';
    }

    const parts = search?.split(' ')?.[0]?.split('.');
    if (search.length > 0 && parts?.length === 3) {
      const time = search?.split(' ')?.[1]?.split(':');

      const startDate = new Date();

      const endDate = new Date();
      startDate.setUTCFullYear(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10)
      );

      endDate.setUTCFullYear(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10)
      );

      startDate.setHours(time?.[0] ? parseInt(time[0], 10) : 0);
      startDate.setMinutes(time?.[1] ? parseInt(time[1], 10) : 0);
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);

      endDate.setHours(time?.[0] ? parseInt(time[0], 10) : 23);
      endDate.setMinutes(time?.[1] ? parseInt(time[1], 10) : 59);
      endDate.setSeconds(59);
      endDate.setMilliseconds(999);

      filters_date = [
        `$gte:${startDate.toISOString()}`,
        `$lte:${endDate.toISOString()}`,
      ];
      search = '';
    }

    const { data } = await $api.get<Paginated<User>>('api/users/list', {
      params: {
        page: page + 1,
        limit: pageSize,
        search,
        searchBy: ['telegram_name', 'username'],
        'filter.is_premium': filters_is_premium,
        'filter.last_active_at': filters_date,
        'filter.utm_source': utmSource,
        sortBy: sortingValue,
      },
    });

    if (filters_date) {
      const { data: fallbackData } = await $api.get<Paginated<User>>(
        'api/users/list',
        {
          params: {
            page: page + 1,
            limit: pageSize,
            search,
            searchBy: 'telegram_name',
            'filter.is_premium': filters_is_premium,
            'filter.created_at': filters_date,
          },
        }
      );

      return {
        ...data,
        data: unionWith(data.data, fallbackData.data, (a, b) => a.id === b.id),
      };
    }

    return data;
  };

  const getUser = async (userID: number) => {
    try {
      const response = await $api.get<Paginated<User>>('api/users/list', {
        params: { page: 1, limit: 10, search: userID },
      });

      const user = response.data.data?.at(0);

      if (!user) {
        return;
      }
      return user;
    } catch (error) {
      console.log(error);
    }
  };

  const patchUser = async (userID: number, user: User) => {
    await $api.patch(`/users/${userID}`, { ...user });
  };

  const downloadCSV = async () => {
    const { data } = await $api.get('api/users/csv', {
      responseType: 'blob',
    });

    const virtualLink = window.document.createElement('a');
    virtualLink.href = window.URL.createObjectURL(data);
    virtualLink.download = `users-${new Date().toISOString()}.csv`;
    virtualLink.click();
    virtualLink.remove();
  };

  return {
    getUsersStatistic,
    getUsersList,
    getUser,
    patchUser,
    getPaginatedUsersList,
    downloadCSV,
    upgradeUserIncomePercent,
    upgradeUserDuckPremiumStatus,
  };
};

export const usersService = usersApi();
