import { create, useStore } from 'zustand';

interface AnaliticsStore {
  type: 'task' | 'campaign';
  id: string | null;
  loading: boolean;
  setId: (id: string) => void;
  setLoading: (loading: boolean) => void;
  title: string;
  setTitle: (title: string) => void;
  setType: (type: 'task' | 'campaign') => void;
}

export const analitics = create<AnaliticsStore>(set => ({
  type: 'task',
  id: null,
  loading: false,
  setId: id => set({ id }),
  setLoading: loading => set({ loading }),
  title: '',
  setTitle: title => set({ title }),
  setType: type => set({ type }),
}));

export const useAnaliticsStore = () => useStore(analitics);
